import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Button, TextField, CircularProgress } from '@mui/material';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import axios from 'axios';

const PromoPage = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [user, setUser] = useState(null);
  const [isTyping, setIsTyping] = useState(false); // Состояние для анимации печатания

  // Реф для контейнера сообщений
  const messagesEndRef = useRef(null);

  // Инициализация Telegram WebApp
  useEffect(() => {
    const initData = window.Telegram.WebApp.initDataUnsafe;
    setUser(initData?.user);
  }, []);

  // Прокрутка вниз после каждого обновления сообщений
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Логика добавления приветственного сообщения
  useEffect(() => {
    if (user) {
      setMessages((prevMessages) => [
        { sender: 'bot', text: `Здравствуйте, ${user.first_name} ${user.last_name}!` },
        ...prevMessages,
      ]);
    }
  }, [user]);

  // Отправка сообщения
  const handleSend = async () => {
    if (!input.trim()) return;

    // Добавление сообщения пользователя
    setMessages((prev) => [...prev, { sender: 'user', text: input }]);

    // Включаем анимацию "печатает..."
    setIsTyping(true);

    try {
      // Отправка запроса на Make.com
      const response = await axios.post('https://hook.eu2.make.com/cr5n83c1vtkzcxizg7475uj2g5hhgv5c', {
        user_id: user?.id || 'unknown',
        message: input,
      });

      // Останавливаем анимацию "печатает..."
      setIsTyping(false);

      // Убираем все, что внутри 【】 и сами скобки
      const cleanedText = response.data.replace(/【[^】]*】/g, '').trim();

      // Добавление очищенного ответа от Make.com
      setMessages((prev) => [
        ...prev,
        { sender: 'bot', text: cleanedText }
      ]);
    } catch (error) {
      console.error('Ошибка при отправке:', error);
      setIsTyping(false);
      setMessages((prev) => [...prev, { sender: 'bot', text: 'Ошибка. Попробуйте снова.' }]);
    }

    setInput('');
  };

  // Обработка нажатия Enter
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Отменяем стандартное поведение (переход на новую строку)
      handleSend();
    }
  };

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      height: "calc(100vh - 226px)",
      justifyContent: "flex-start"
    }}>

    </Box>
  );
};

export default PromoPage;
